import React, { Fragment, useEffect, useState } from 'react'
import { Alert, Badge, Button, Col, Container, Form, Row } from 'react-bootstrap'
import Calendar from 'react-calendar';
import Parser from 'html-react-parser';
import 'react-calendar/dist/Calendar.css';
import './index.css'
import { trackPromise } from 'react-promise-tracker';
import VistoriaService from '../../services/VistoriaService';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { successToast } from '../../components/DommusToast';
import guService from '../../services/GuService';
import CardAgendados from './CardAgendados';
import ticketService from '../../services/TicketService';
import CardVistoriaAgendada from './CardVistoriaAgendada';
import 'moment/locale/pt-br';
import formatDommus from "../../helpers/format";
import { groupBy } from "core-js/actual/object/group-by";

function VistoriasEntrega(props) {
  const [data, setData] = useState(null);
  const [horarioVistoria, setHorarioVistoria] = useState('');
  const [estagio, setEstagio] = useState(1);
  const [horarios, setHorarios] = useState();
  const [agendados, setAgendados] = useState();
  const [validacao, setValidacao] = useState();
  const [processos, setProcessos] = useState();
  const [optionsProcesso, setOptionsProcesso] = useState();
  const [dadosHorarios, setDadosHorarios] = useState();
  const [ticket, setTicket] = useState();
  const [vistoriador, setVistoriador] = useState([]);
  const [dataHorario, setDataHorario] = useState();
  const [agendado, setAgendado] = useState(null);
  const [badgeAtivo, setBadgeAtivo] = useState('');
  const [janelasAgendamento, setJanelasAgendamento] = useState(null);
  const [retornoAgendamento, setRetornoAgendamento] = useState(false);
  const [dataMinima, setDataMinima] = useState(new Date());
  const [dataMaxima, setDataMaxima] = useState(new Date());
  const [validacaoAgendamento, setValidacaoAgendamento] = useState(true)
  const [agenda, setAgenda] = useState()
  const idProcesso = guService.getLocalState("Processo");


  useEffect(() => {
    buscaAgendados()
    buscarEtapa()
    if(props.idAgenda){
      buscarAgenda()
    }
  }, [])

  function formataHora(hora){
    if(typeof(hora) == 'string'){
        return String(hora.substring(0, 5));
    }else{
        return hora;
    }
  }

  function buscarAgenda(){
    trackPromise(VistoriaService.buscarAgendaPorId(props.idAgenda)).then((response)=>{
      setAgenda(response.data);
    })
  }

  function buscarChamados() {
    trackPromise(ticketService.buscarChamadosAsssitenciaTecnica(idProcesso)).then(
      (response) => {
        setProcessos(response.data);
        setOptionsProcesso(response.data.map(processo => {
          return {label: processo.ticket.id_ticket + " - " + processo.ticket.assunto, value: processo.ticket.id_ticket}
        }))
        setEstagio(2);
      }
    )
  }

  function calcularTempoComPrazoAntecedencia(horas, data) {
    return data.setHours(data.getHours() + horas)
  }

  function buscarEtapa() {
    trackPromise(VistoriaService.buscaEtapaVistoria(idProcesso, props.tipo, props.idAgenda)).then(response => {
      if (response.data) {
        if(response.data?.status_vistoria?.agendamento_vistoria && props.tipo == "V"){
          setAgendado(
            Object.assign(response.data?.status_vistoria?.agendamento_vistoria,
            {reagendamento_pos_data_vistoria: response.data.status_vistoria?.reagendamento_pos_data_vistoria })
          );
        } else if(response.data?.status_vistoria?.agendamento_entrega && props.tipo == "E"){
          setAgendado(
            Object.assign(response.data?.status_vistoria?.agendamento_entrega,
            {reagendamento_pos_data_vistoria: response.data.status_vistoria?.reagendamento_pos_data_vistoria })
          );
        } else if(response.data?.status_vistoria?.agendamento_diversos && props.tipo == "O"){
          setAgendado(
            Object.assign(response.data?.status_vistoria?.agendamento_diversos,
            {reagendamento_pos_data_vistoria: response.data.status_vistoria?.reagendamento_pos_data_vistoria })
          );
        }

        setValidacao(response.data?.status_vistoria);
        let novoJanelasAgendamento = [];
        let novoDataMinima = null;
        let novoDataMaxima = null
        let tempoHoje = (new Date()).getTime();

        if(Array.isArray(response.data?.status_vistoria?.janelas_agendamento)){
          let janelasFiltradas = response.data?.status_vistoria?.janelas_agendamento.filter((janela) => {
            return (janela.tipo === 'VE') || (janela.tipo === props.tipo);
          }).sort((a, b) => (new Date(a.data_inicio).getTime()) > (new Date(b.data_inicio).getTime()) ? 1 : -1);

          novoJanelasAgendamento =
            janelasFiltradas.map((janela) => {
              janela.prazo_antecedencia = Number(janela.prazo_antecedencia)

              let retorno = {
                data_inicio: null,
                data_fim:null
              }

              if(janela.data_inicio){
                let dataInicio = new Date(janela.data_inicio + " 00:00:00");
                dataInicio.setHours(0,0,0,0);
                let tempoInicio = dataInicio.getTime();
                //Se não houver uma data minima definida por janelas anteriores, e hoje não estiver dentro da janela OU
                //se a data de inicio da janela for maior do que hoje, e a data inicial da janela for menor do que a data inicial da janela anterior
                //Caso a condição acima for verdadeira, define a data inicial sendo da janela, caso contrário a data inicial começa sendo hoje.
                if((!novoDataMinima && validarDatasJanela(janela.data_inicio, janela.data_fim)) ||
                  ((tempoInicio > tempoHoje) && novoDataMinima instanceof Date && (tempoInicio < novoDataMinima.getTime()))
                ) {
                    novoDataMinima = new Date(janela.data_inicio + " 00:00:00");
                    if(
                      janela.prazo_antecedencia &&
                      novoDataMinima instanceof Date &&
                      novoDataMinima.getTime() < calcularTempoComPrazoAntecedencia(janela.prazo_antecedencia, new Date())
                    ) {
                      novoDataMinima = calcularTempoComPrazoAntecedencia(janela.prazo_antecedencia, new Date())
                    }
                  } else {
                    if(tempoInicio < novoDataMinima.getTime()){
                      novoDataMinima = new Date();
                      if(janela.prazo_antecedencia) {
                        novoDataMinima = calcularTempoComPrazoAntecedencia(janela.prazo_antecedencia, novoDataMinima)
                      }
                    }

                  }
                retorno.data_inicio = tempoInicio;
              }else{
                novoDataMinima = new Date();
                if(janela.prazo_antecedencia) {
                  novoDataMinima = calcularTempoComPrazoAntecedencia(janela.prazo_antecedencia, novoDataMinima)
                }
              }
              if(janela.data_fim){
                let dataFim = new Date(janela.data_fim + " 00:00:00");
                dataFim.setHours(0,0,0,0);
                if(!novoDataMaxima || novoDataMaxima.getTime() < dataFim.getTime()) {
                  novoDataMaxima = dataFim
                }
                retorno.data_fim = dataFim.getTime()
              }
              return retorno;
            });
        }
        setJanelasAgendamento([...novoJanelasAgendamento]);
        if(!novoDataMinima){
          novoDataMinima = new Date();
        }
        const dataQueVaiProState = new Date(novoDataMinima);
        dataQueVaiProState.setSeconds(0)
        dataQueVaiProState.setMilliseconds(0)
        const dataMaximaQueVaiProState = new Date(novoDataMaxima)
        setDataMaxima(dataMaximaQueVaiProState)
        setDataMinima(dataQueVaiProState);

      };
    })
  }

  function validarDatasJanela(dataInicial, dataFinal) {
    const tempoInicialJanela = new Date(`${dataInicial} 00:00:00`).getTime()
    const tempoFinalJanela = new Date(`${dataFinal} 00:00:00`).getTime()
    const tempoDataMinima = dataMinima.getTime()

    return tempoFinalJanela > tempoDataMinima || tempoDataMinima > tempoInicialJanela
  }

  function buscaAgendados() {
    trackPromise(VistoriaService.buscaAgendados(idProcesso, props.tipo, props?.idAgenda ?? null)).then(response => {
      if (response.data) {
        setAgendados(response.data.agendados);
        if(response.data.agendados.length && response.data.agendados.at(-1).motivo_recusa_vistoria) {
          setValidacaoAgendamento(false)
        }
      }
    })
  }

  const formatarHorarios = (horarios) => {
    let agrupado =  Object.groupBy(horarios, ({ label }) => label);
    return Object.keys(agrupado).map((key)=>{
      return {
        label: key,
        value: agrupado[key].map((item)=>item.value)
      }
    })
  }

  const handleHoraChange = (event) => {
    setEstagio(4);
    setBadgeAtivo('badge'+ event.value)
    setHorarioVistoria(event.value);
    const horarioSelecionado = dadosHorarios.filter(horario => event.value.includes(horario.id))
    const horaFormatada = horarioSelecionado.map(horario => {return horario.horario_inicio});
    const dataIso =  data.toISOString()
    setDataHorario(new Date(`${dataIso.slice(0, 10)}T${Array.isArray(horaFormatada) ? horaFormatada[0] : horaFormatada}`));
    setVistoriador(horarioSelecionado.map(horario => {return horario.vistoriador}));
  };

  const handleChangeData = (event) => {
    setEstagio(1)
    setData(event);
    if(props?.tipo == 'A'){
      buscarChamados()
    }else{
      buscaHorariosDisponiveis(event)
    }
  };

  function buscaHorariosDisponiveis(dataCalendario) {
    trackPromise(VistoriaService.buscarHorariosDisponiveis(dataCalendario.toISOString(), props.tipo, idProcesso, props?.idAgenda ?? null)).then(response => {
      if (response.data.horarios) {
        let arrayHorarios = [];
        let horariosTratar = response.data.horarios;
        if(typeof horariosTratar === 'object'){
          for(let chave in horariosTratar){
            arrayHorarios.push(horariosTratar[chave])
          }
        }else if(Array.isArray(horariosTratar)){
          arrayHorarios = horariosTratar.slice();
        }
        let horario = arrayHorarios.map(horarios => {
          return { label: formataHora(horarios.horario_inicio), value: horarios.id }
        });
        setDadosHorarios(arrayHorarios)
        setHorarios(horario);
        setEstagio(3);
      }
    })
  }

  function handleChangeProcesso(event){
    buscaHorariosDisponiveis()
    setTicket(event.value)
  }

  function salvarHorario() {
  let dados =  {
      tipo: props.tipo,
      horario: horarioVistoria,
      id_processo: idProcesso,
      data: dataHorario.getFullYear() + '-' + (dataHorario.getMonth() + 1) + '-' + dataHorario.getDate(),
      ticket: ticket
      }
      if(props.tipo == 'A'){
        dados['vistoriador'] = JSON.parse(vistoriador);
      }
      else if(props.tipo == 'O'){
        dados['id_agenda'] = props?.idAgenda ?? null;
      }
    trackPromise(VistoriaService.salvarHorarioVistoria(dados)).then(response => {
      if(response.data?.status === 'success'){
        setRetornoAgendamento(true);
        setAgendado(response.data.agendamento);
        successToast.fire({
          text: "Agendamento realizado com sucesso!.",
        });
      }else if(response.data?.mensagem){
        throw response.data?.mensagem;
      }else{
        throw "";
      }

    }).catch(error => {
      Swal.fire(
        'Erro!',
        'Houve um problema ao salvar o agendamento!' + error,
        'error'
      )
    })
  }

  function retrocederAgendamento() {
    let dados =  {
        tipo: props.tipo == "V" ? "V" : props.tipo == "O" ? "O" : "AE",
        id_processo: idProcesso,
        id_agenda: props.idAgenda ?? null
      }
      trackPromise(VistoriaService.retrocederAgendamento(dados)).then(res => {
        setRetornoAgendamento(false);
        setValidacao(res.data);
        setAgendados(res.data.agendados)
        setAgendado(null);
        setBadgeAtivo('');
        setEstagio(1);
        setData(null)
        successToast.fire({
          text: "Agendamento cancelado com sucesso!.",
        });
      }).catch(error => {
        Swal.fire(
          'Erro!',
          'Houve um problema ao cancelar o agendamento!' + error,
          'alert'
        )
      })
    }

  const validaDataDentroDaJanela = ({date, view}) => {
    if(view === 'month'){
      date.setHours(0,0,0,0);
      let tempoData = date.getTime();
      let indiceJanelaData = janelasAgendamento?.findIndex((janela) => {
        return  ((!janela.data_inicio) || (janela.data_inicio <= tempoData)) &&
         ((!janela.data_fim) || (janela.data_fim >= tempoData));
      });
      return ['V', 'E', 'O'].includes(props.tipo) && indiceJanelaData === -1;
    }

    return false;

  };

  function filtrarHorariosDiaAtual(horario) {
    const horaMinuto = horario.label.split(':')

    return data.setHours(Number(horaMinuto[0]), Number(horaMinuto[1])) >= dataMinima.getTime()
  }

  return (
    <div>
      <div className="tituloNovoChamado" style={{ backgroundColor: props.cor }}>
        <h5 className='h3 text-center mt-3'>{props.titulo}</h5>
      </div>
      {props.html &&
        <Alert variant="info">
          {Parser(props.html)}
        </Alert>}
      <Container>
        <br></br>
        <Row>
          <Col lg={6} sm={12}>
            {
              (
                ((janelasAgendamento && janelasAgendamento.length)) &&
                  <Calendar
                    onChange={(e) => handleChangeData(e)}
                    value={data}
                    minDate={dataMinima || new Date()}
                    maxDate={dataMaxima || new Date()}
                    defaultValue={dataMinima}
                    tileDisabled={validaDataDentroDaJanela}
                    className="calendario-visotria"
                    locale="pt-br"
                  />
              ) || <div className="alert alert-info">Não existem datas disponíveis para {props.tipo == 'V' ? 'vistoria' : 'entrega' }</div>
            }
          </Col>
          <Col  lg={6} sm={12}>
            {validacaoAgendamento &&
            (
              (( // Bloco apenas para agendamento de vistoria
                (validacao?.situacao == 'A' && validacao?.pos_vistoria == false && !validacao.agendamento_vistoria && !retornoAgendamento) ||
                (validacao?.pos_vistoria == true && !validacao.agendamento_vistoria && !retornoAgendamento)
              ) && props.tipo == 'V') ||
              (( // Bloco apenas para agendamento de entrega de chaves
                (validacao?.situacao == 'A' && validacao?.pos_vistoria == true && !validacao.agendamento_entrega && !retornoAgendamento) ||
                (validacao?.situacao == 'F' && validacao?.pos_vistoria == false && !validacao.agendamento_entrega && !retornoAgendamento)
              ) && props.tipo == 'E' && validacao.agendamento_vistoria) ||
                props.tipo == 'A' || // Se for assistencia técnica passa reto
                (props.tipo == 'O' && !validacao?.agendamento_diversos && !agendado)
              )
              ?
              <>
                <div className='card-vistoria'>
                    <header className="h4">Horário do Agendamento</header>
                    {
                      !(data) && <div className="alert alert-warning"> Selecione uma data no
                      calendário ao lado e, em seguida, escolha um horário para o
                      seu agendamento!</div>
                    }
                    {
                      !(data) && (janelasAgendamento && janelasAgendamento.length) &&
                      <div className='info-orientacao-section'>
                        {agenda && agenda?.orientacao &&
                          <div dangerouslySetInnerHTML={{__html: agenda?.orientacao}}></div>
                        }
                        <div className="alert alert-info">
                        O{janelasAgendamento.length > 1 ? "s " : " "} período{janelasAgendamento.length > 1 ? "s " : " "}
                        de agendamento liberado{janelasAgendamento.length > 1 ? "s " : " "}
                        para a sua unidade {janelasAgendamento.length > 1 ? "são " : "é"}:
                        <br/>
                        <ul className="ml-5">
                          {
                            janelasAgendamento.map((janela, indice) => <li key={indice}>{
                              (janela.data_inicio && ((janela.data_fim && "De ") || "À Partir do dia ") || "") +
                              ((janela.data_inicio && formatDommus.formatarParaDataBr((new Date()).setTime(janela.data_inicio))) || "") +
                              (!janela.data_inicio && ((janela.data_fim && "Até o dia ") || "Data livre - condicionado a disponibilidade do vistoriador") || "") +
                              ((janela.data_inicio && janela.data_fim && " até ") || "") +
                              ((janela.data_fim && formatDommus.formatarParaDataBr((new Date()).setTime(janela.data_fim))) || "")
                            }</li>)
                          }
                        </ul>
                        </div>
                       </div>
                    }
                    {
                      data && !(horarios && horarios.length) &&
                      <div className="alert alert-warning"> Nenhum horário
                      disponível para a data selecionada</div>
                    }

                    {props.tipo == 'A' && estagio > 1 ?
                      <>
                        <Select
                          options={optionsProcesso}
                          onChange={(e) => handleChangeProcesso(e)}
                        /> <br /> </> : <></>}
                    {estagio > 2 &&
                      <Row>
                        {horarios.filter(filtrarHorariosDiaAtual).length && formatarHorarios(horarios.filter(filtrarHorariosDiaAtual)).map(horario => {
                          return <Fragment key={horario.value}>
                            <Col sm={2} className='col-badge'>
                              <h4 className='h4-badge'>
                                <Badge className="badge-horario" variant={'primary'} style={badgeAtivo == 'badge' + horario.value ? { backgroundColor: "#f7941d", color: "#ededed", cursor: "pointer" } : { backgroundColor: "#e8e8e8", color: "#36454F", fontWeight: "normal", cursor: "pointer" }} key={horario.value} id="badge-horario" onClick={(e) => { handleHoraChange(horario) }}>{horario.label}</Badge>
                              </h4>
                            </Col>
                          </Fragment>
                        }) || <Alert variant='info'>
                        Nenhum horario disponível para a data selecionada.
                      </Alert>}
                      </Row>
                    }
                    {estagio > 3 &&
                      <>
                        <br></br>
                        <div className="d-flex justify-content-end">
                          <Button variant="primary" className="ml-auto" onClick={() => salvarHorario()}>Confirmar Agendamento</Button>
                        </div>
                      </>
                    }
                  </div>
              </> :
              agendado && (typeof agendado == 'object') && agendado.id_agenda_entrega ?
                <CardVistoriaAgendada
                  vistoria={agendado}
                  titulo={props.tipo === 'O' ? agenda?.descricao : props.titulo}
                  retrocederAgendamento={retrocederAgendamento}
                  tipo={props.tipo}
                  alternarModalProcuracao={props.alternarModalProcuracao}
                /> : ((validacao && ((props.tipo == 'V' && validacao.agendamento_vistoria?.id_motivo_recusa_aprovacao) || (props.tipo == 'E' && validacao.agendamento_entrega?.id_motivo_recusa_aprovacao)) && (
                  <Alert variant='info'>
                    <strong>Vistoria Reprovada!</strong> Devido a última vistoria ter sido reprovada, aguarde o contato da nossa equipe para um novo agendamento.
                  </Alert>
                )) || (((props.tipo == 'E' && !validacao?.agendamento_vistoria) || (validacao && (validacao?.situacao == 'V' || validacao?.situacao == 'F'))) && (validacao?.pos_vistoria == true && validacao?.situacao == 'F' ? <Alert variant='info'><strong>ATENÇÃO:</strong> O processo de <strong>VISTORIA</strong> da sua unidade foi concluído, mas a <strong>ENTREGA DAS CHAVES</strong> ainda não foi liberada. Entre em contato com o Relacionamento com o Cliente para verificar sobre a liberação da sua unidade para o agendamento da Entrega das Chaves.</Alert> : <Alert variant='info'>
                  <strong>Vistoria em Andamento</strong> Sua unidade se encontra em processo de VISTORIA, aguardando conclusão para liberação da entrega das chaves. <a href="#" onClick={props.eventoAbreModalVistoria}>Clique aqui</a> para ver seus agendamentos.
                </Alert>)) || "")}
          </Col>
        </Row>
      </Container>
      {
        (agendados?.length &&
        <Container>
          <CardAgendados agendados={agendados} />
        </Container> || <></>)
      }
      <Form.Group as={Col} controlId="formSubmit">
        <br></br>
        <div className="d-flex justify-content-end">
          <Button variant="success" className="ml-auto" onClick={() => props.setCloseModal(false)}>Fechar</Button>
        </div>
      </Form.Group>

    </div>
  )
}

export default VistoriasEntrega
